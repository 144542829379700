// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import App from "./App";
import Vuetify from "vuetify";
import router from "./router";
import "font-awesome/css/font-awesome.css";
import "vue-tel-input/dist/vue-tel-input.css";
import "./theme/default.styl";
import VeeValidate from "vee-validate";
import Truncate from "lodash.truncate";
import VueLazyload from "vue-lazyload";

window._ = require("lodash");

Vue.config.productionTip = false;

// Store
import store from "./store";
import VueStash from "vue-stash";
Vue.use(VueStash);
// Global filters
Vue.filter("truncate", Truncate);
Vue.use(VeeValidate, {
  fieldsBagName: "formFields"
});
Vue.use(VueLazyload);
Vue.use(Vuetify, {
  // theme: {
  //   primary: colors.indigo.base, // #E53935
  //   secondary: colors.indigo.lighten4, // #FFCDD2
  //   accent: colors.indigo.base // #3F51B5
  // },
  options: {
    themeVariations: ["primary", "secondary", "accent"],
    extra: {
      mainToolbar: {
        color: "primary",
      },
      sideToolbar: {},
      sideNav: "primary",
      mainNav: "primary lighten-1",
      bodyBg: "",
    }
  }
});

// Vue.config.devtools = true;

// Bootstrap application components

/* eslint-disable no-new */
new Vue({
  el: "#app",
  components: {
    App
  },
  data: {
    store,
  },
  router,
  render: h => h(App)
});
