import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueAuth from '@websanova/vue-auth';
import Router from 'vue-router';
import paths from './paths';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';



Vue.use(Router);
const router = new Router({
  base: '/',
  mode: 'hash',
  linkActiveClass: 'active',
  routes: paths
});

Vue.router = router;
Vue.use(VueAxios, axios);
Vue.use(VueAuth, {
  auth: {
    request: function (req, token) {
      this.options.http._setHeaders.call(this, req, {
        Authorization: 'Bearer ' + token
      });
    },
    response: function (res) {
      // Get Token from response body
      // console.log(res);
      return res.data.token;
    }
  },
  // auth: require('@websanova/vue-auth/drivers/auth/bearer.js'),
  http: require('@websanova/vue-auth/drivers/http/axios.1.x.js'),
  router: require('@websanova/vue-auth/drivers/router/vue-router.2.x.js'),
  loginData: {
    url: process.env.VUE_APP_api_server_URL + 'v1/auth/login',
    fetchUser: false
  },
  logoutData: {
    url: process.env.VUE_APP_api_server_URL + 'logout',
    method: 'POST',
    redirect: 'login',
    makeRequest: false
  },
  fetchData: {
    url: process.env.VUE_APP_api_server_URL + 'v1/user',
    method: 'GET',
    authType: 'bearer'
  },
  rolesVar: 'role',
  refreshData: {
    enabled: false
  }
});

// router gards
// router.beforeEach((to, from, next) => {
//   NProgress.start();
//   next();
// });

router.afterEach((to, from) => {
  // ...
  NProgress.done();
});

// router.beforeEach((to, from, next) => {
//   const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
//   if (requiresAuth) {
//     next('/login');
//   } else {
//     NProgress.start();
//     next();
//   }
// });

router.beforeEach((to, from, next) => {
  if (to.path !== '/login') {
    if (Vue.auth.check()) {
      // logger('There is a token, resume. (' + to.path + ')')
      next();
    } else {
      // logger('There is no token, redirect to login. (' + to.path + ')')
      next('login');
    }
  } else {
    NProgress.start();
    // logger('You\'re on the login page')
    next(); // This is where it should have been
  }
  // next(); - This is in the wrong place
});



export default router;
