export default [

  {
    path: '*',
    meta: {
      public: true,
    },
    redirect: {
      path: '/404'
    }
  },
  {
    path: '/404',
    meta: {
      public: true,
    },
    name: 'NotFound',
    component: () => import(
      /* webpackChunkName: "routes" */
      /* webpackMode: "lazy" */
      `@/pages/NotFound.vue`
    )
  },
  {
    path: '/403',
    meta: {
      public: true,
    },
    name: 'AccessDenied',
    component: () => import(
      /* webpackChunkName: "routes" */
      /* webpackMode: "lazy" */
      `@/pages/Deny.vue`
    )
  },
  {
    path: '/500',
    meta: {
      public: true,
    },
    name: 'ServerError',
    component: () => import(
      /* webpackChunkName: "routes" */
      /* webpackMode: "lazy" */
      `@/pages/Error.vue`
    )
  },
  {
    path: '/login',
    meta: {
      public: true,
    },
    name: 'Login',
    component: () => import(
      /* webpackChunkName: "routes" */
      /* webpackMode: "lazy" */
      `@/pages/Login.vue`
    )
  },
  {
    path: '/',
    meta: {},
    name: 'Root',
    redirect: {
      name: 'AuctionsActive'
    }
  },
  // {
  //   path: '/dashboard',
  //   meta: { breadcrumb: true },
  //   name: 'Dashboard',
  //   component: () => import(
  //     /* webpackChunkName: "routes" */
  //     /* webpackMode: "lazy" */
  //     `@/pages/Dashboard.vue`
  //   )
  // },
  {
    path: '/auction/:slug',
    meta: {
      breadcrumb: true
    },
    name: 'Auction',
    component: () =>
      import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy" */
        `@/components/auction/AuctionView.vue`
      )
  },
  // {
  //   path: '/auction/:id/registration',
  //   meta: {
  //     breadcrumb: true
  //   },
  //   name: 'AuctionRegistration',
  //   component: () =>
  //       import(
  //         /* webpackChunkName: "routes" */
  //         /* webpackMode: "lazy" */
  //         `@/pages/auction/AuctionRegistrationList.vue`
  //       )
  // },
  {
    path: '/auctions/active',
    meta: {
      breadcrumb: true,
      // requiresAuth: true
    },
    name: 'AuctionsActive',
    props: (route) => ({ type: route.query.type }),
    component: () => import(
      /* webpackChunkName: "routes" */
      /* webpackMode: "lazy" */
      `@/pages/auction/AuctionList.vue`
    )
  },
  {
    path: '/auctions/inactive',
    meta: {
      breadcrumb: true,
      // requiresAuth: true
    },
    name: 'AuctionsInactive',
    props: (route) => ({
      type: route.query.type
    }),
    component: () =>
      import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy" */
        `@/pages/auction/AuctionList.vue`
      )
  },


];
