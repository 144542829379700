export default [{
  name: 'APP_LOGIN_SUCCESS',
  callback: function (e) {
    this.$router.push({
      path: 'dashboard'
    });
  }
},
{
  name: 'APP_LOGOUT',
  callback: function (e) {
    this.snackbar = {
      show: true,
      color: 'green',
      text: 'Logout successfully.'
    };
    this.$router.replace({
      path: '/login'
    });
  }
},
{
  name: 'APP_PAGE_LOADED',
  callback: function (e) {}
},
{
  name: 'APP_AUTH_FAILED',
  callback: function (e) {
    this.$router.push('/login');
    this.$message.error('Token has expired');
  }
},
{
  name: 'APP_BAD_REQUEST',
  // @error api response data
  callback: function (msg) {
    this.$message.error(msg);
  }
},
{
  name: 'APP_ACCESS_DENIED',
  // @error api response data
  callback: function (msg) {
    this.$message.error(msg);
    this.$router.push('/forbidden');
  }
},
{
  name: 'APP_RESOURCE_DELETED',
  // @error api response data
  callback: function (msg) {
    this.$message.success(msg);
  }
},
{
  name: 'APP_RESOURCE_UPDATED',
  // @error api response data
  callback: function (msg) {
    this.$message.success(msg);
  }
},
{
  name: 'APP_NEW_BID',
  callback: function (e) {
    this.snackbar = {
      show: true,
      color: 'green',
      text: 'Отримана нова ставка'
    };
    // this.$router.replace({
    //   path: '/login'
    // });
  }
},
{
  name: 'APP_PLACE_BID_ERROR',
  callback: function (e) {
    this.snackbar = {
      show: true,
      color: 'red',
      text: 'Ставка не принята'
    };
    // this.$router.replace({
    //   path: '/login'
    // });
  }
},
{
  name: 'APP_UPD_BID',
  callback: function (e) {
    this.snackbar = {
      show: true,
      color: 'green',
      text: 'Статус ставки оновлено'
    };
    // this.$router.replace({
    //   path: '/login'
    // });
  }
},
{
  name: 'APP_UPD_LOT',
  callback: function (e) {
    this.snackbar = {
      show: true,
      color: 'green',
      text: 'Статус лота оновлено'
    };
    // this.$router.replace({
    //   path: '/login'
    // });
  }
},
{
  name: 'APP_BAD_REQUEST',
  callback: function (e) {
    this.snackbar = {
      show: true,
      color: 'red',
      text: 'Помилка 500'
    };
    window.location.reload();
    // this.$router.replace({
    //   path: '/login'
    // });
  }
},
{
  name: 'APP_SOCKET_DISCONNECTED',
  callback: function (e) {
    this.snackbar = {
      show: true,
      color: 'red',
      text: 'Помилка з`єднання з сервером'
    };
    window.location.reload();
    // this.$router.replace({
    //   path: '/login'
    // });
  }
},
{
  name: 'APP_SOCKET_CONNECTED',
  callback: function (e) {
    this.snackbar = {
      show: true,
      color: 'green',
      text: 'З`єднання встановлено'
    };
    // this.$router.replace({
    //   path: '/login'
    // });
  }
},
{
  name: 'APP_MEMBER_REGISTERED',
  callback: function (e) {
    this.snackbar = {
      show: true,
      color: 'green',
      text: 'Зареєстрован новий участник'
    };
    // this.$router.replace({
    //   path: '/login'
    // });
  }
},
{
  name: 'APP_USER_REGISTERED',
  callback: function (e) {
    this.snackbar = {
      show: true,
      color: 'green',
      text: 'Зареєстрован новий участник'
    };
    // this.$router.replace({
    //   path: '/login'
    // });
  }
},
{
  name: 'APP_USER_VERIFIED',
  callback: function (e) {
    this.snackbar = {
      show: true,
      color: 'green',
      text: 'Реєстрацію участника ухвалено'
    };
    // this.$router.replace({
    //   path: '/login'
    // });
  }
},
];