<template>
  <v-layout row class="align-center layout px-4 pt-4 app--page-header">
    <div class="page-header-left">
      <h3 class="pr-3">{{title}}</h3>
    </div>
    <v-breadcrumbs divider="">
      <!-- <v-breadcrumbs-item>
      <v-icon larg>home</v-icon>
      </v-breadcrumbs-item> -->
      <v-breadcrumbs-item v-for="(item,key) in breadcrumbs" :key="key" activeclass="auction-title">
        {{ item.title }}
      </v-breadcrumbs-item>
    </v-breadcrumbs>
    <v-spacer></v-spacer>
    <div class="page-header-right">
      <v-progress-circular indeterminate color="primary" v-if="reload_data"></v-progress-circular>
      <v-btn icon @click="reload">
        <v-icon class="text--secondary">refresh</v-icon>
      </v-btn>
    </div>
  </v-layout>
</template>

<script>
import menu from '@/api/menu';
export default {
  store: ['breadcrumbs_items', 'reload_data', 'auction'],
  data () {
    return {
      title: ''
    };
  },
  computed: {
    breadcrumbs: function () {
      let breadcrumbs = [];
      menu.forEach(item => {
        if (item.items) {
          let child = item.items.find(i => {
            return i.component === this.$route.name;
          });
          if (child) {
            breadcrumbs.push(item.title);
            breadcrumbs.push(child.title);
            this.title = child.title;
          } else {
            // console.log(item.name);
          }
        } else {
          if (this.$route.name == 'Auction') {
            this.title = this.auction.title + ' ' + this.auction.start_date;

            let item = {};
            item.title = this.title;

            breadcrumbs.push(item.title);
          } else if (
            item.name === this.$route.name &&
            this.$route.name != 'Auction'
          ) {
            this.title = item.title;
            breadcrumbs.push(item.title);
          }
        }
      });
      // this.breadcrumbs_items.forEach(item1 => {
      //   breadcrumbs.push(item1);
      // });
      return breadcrumbs;
    }
  },
  methods: {
    reload: function (params) {
      this.reload_data = true;
    }
  }
};
</script>
<style lang="css" scoped>
.auction-title {
  font-size: 26px !important;
}
</style>