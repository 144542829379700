export default {
  auction: null,
  active_lots: null,
  members: null,
  active_tab: 'registration',
  offline_bids: {
    lotId: null,
    lot: null,
    hasPreviousLot: null,
    hasNextLot: null
  },
  search: null,
  reload_data: false,
  reload_bids: false,
  reload_members: false,
  pause_loading: false,
  breadcrumbs_items: [
    {
      title: '',
      url: ''
    }
  ]
};
