const Menu = [
  {
    title: 'Головна',
    group: 'apps',
    icon: 'dashboard',
    name: 'Dashboard',
  },
  {
    header: 'Аукціони'
  },
  {
    title: 'Активні аукціони',
    group: 'apps',
    icon: 'gps_fixed',
    name: 'AuctionsActive',
    component: 'AuctionList'
  },
  {
    title: 'Попередні аукціони',
    group: 'apps',
    name: 'AuctionsInactive',
    icon: 'gps_off',
    component: 'AuctionList'
  },
  // {
  //   divider: true
  // },
  // {
  //   header: 'Звіти'
  // },
  // {
  //   title: 'Pages',
  //   group: 'extra',
  //   icon: 'list',
  //   items: [{
  //     name: 'Login',
  //     title: 'Login',
  //     component: 'Login'
  //   },
  //   {
  //     name: '404',
  //     title: '404',
  //     component: 'NotFound'
  //   },
  //   {
  //     name: '403',
  //     title: '403',
  //     component: 'AccessDenied'
  //   },
  //   {
  //     name: '500',
  //     title: '500',
  //     component: 'ServerError'
  //   },
  //   ]
  // },
];
// reorder menu
Menu.forEach((item) => {
  if (item.items) {
    item.items.sort((x, y) => {
      let textA = x.title.toUpperCase();
      let textB = y.title.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
  }
});

export default Menu;
